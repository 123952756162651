<template>
  <el-main class="rh-form">
    <form-fcontinueedit :routetolist="{ name: 'fcontinues' }" />
  </el-main>
</template>
<script>
import FormFcontinueedit from "@/components/rh/formulaires/FormFcontinueedit";

export default {
  components: { "form-fcontinueedit": FormFcontinueedit },
};
</script>
